import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content404Page from "../components/content-404-page.js"

const NotFoundPage = () => (
  <Layout shortLayoutVersion={true}>
      <SEO title="Home" />
        <Content404Page />
    </Layout>
)

export default NotFoundPage
