import React from "react"
import styled from "@emotion/styled"
import tw, {theme} from "twin.macro"
import ServicePagesTemplate from "./service-pages-template"
import { Link } from "gatsby"

const StyledHeadingText = styled("h2")`
    ${tw`uppercase text-center mb-8`};
    font-size: 36px;
    text-center;
    line-height: 55px;
    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 31px;
    }
`

const ContentSleepPage = () => {
  return (
    <ServicePagesTemplate>
        <StyledHeadingText>
            Ups, da ist wohl etwas schief gegangen. Hier gehts zurück zur Startseite.
        </StyledHeadingText>
        <Link tw="p-5 cursor-pointer hover:text-primary underline" to="/">Zurück zur Startseite</Link>
    </ServicePagesTemplate>
  )
}

export default ContentSleepPage
